import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, withRouter, Redirect } from 'react-router-dom'

import Menu from './components/Menu'

import Perfil from './pages/Perfil'
import Questionario from './pages/Questionario'
import Home from './pages/Home'
import Login from './pages/Login'
import EditarPerfil from './pages/EditarPerfil'
import Ranking from './pages/Ranking'
import PageNotFound from './pages/PageNotFound'
import Estatisticas from './pages/Estatisticas'
import Grupos from './pages/Grupos'

import { createTheme, ThemeProvider, Container } from '@material-ui/core'

import Usuario from './services/Usuario';
import Storage from './services/Storage';
import Axios from './services/Axios';
import LoaddingPage from './components/LoadingPage';
import Cadastro from './pages/Cadastro'
import RecuperarSenha from './pages/RecuperarSenha'
import Resumo from './pages/Resumo'
import AcessoAsLeis from './pages/AcessoAsLeis'
import NovoGrupo from './pages/NovoGrupo'
import VerGrupo from './pages/VerGrupo'

export default function App() {
    const [carregado, setCarregado] = useState(false);
    const [isOnline, setOnline] = useState(navigator.onLine);
    const [message, setMessage] = useState(false);

    useEffect(() => {
        // setCarregado(true);
        // return;

        if (Usuario.isLogado())
            loadData();
        else
            setCarregado(true);
    }, []);

    const loadData = () => {
        let data = Storage.get();

        if (data && data.aluno) {
            // setDados(data);
            setCarregado(true);
        } else {
            async function fetchData() {
                let res = await Axios.get('/start.php');
                let data = res.data;

                if (data && data.aluno) {
                    // setDados(data);
                    Storage.set(data);
                    setCarregado(true);
                } else if (data.erro) {
                    console.log(data.erro);

                    if (data.codigo == 404) { // não matriculado 
                        setMessage(data.erro);
                    } else if (data.codigo == 401) { // token expirado
                        setMessage(data.erro);
                        // setTimeout(sair, 2000);
                    } else {
                        setMessage("Ops! Houve algum erro.")
                    }
                } else {
                    // sair
                }
            }

            if (navigator.onLine) {
                fetchData();
                setOnline(true);
            }
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <LoaddingPage loading={!carregado} isOffline={!isOnline} message={message}>
                <Router>
                    <Container maxWidth="sm">
                        <Switch>
                            <PrivateRoute path="/" component={Home} exact />
                            <PrivateRoute path="/ranking" component={Ranking} />
                            <PrivateRoute path="/perfil/editar" component={EditarPerfil} />
                            <PrivateRoute path="/perfil" component={Perfil} />
                            <PrivateRoute path="/questionario/:assunto" component={Questionario} />
                            <PrivateRoute path="/resumo" component={Resumo} />
                            <PrivateRoute path="/estatisticas" component={Estatisticas} />
                            <PrivateRoute path="/grupos/novo" component={NovoGrupo} />
                            <PrivateRoute path="/grupos/:id" component={VerGrupo} />
                            <PrivateRoute path="/grupos" component={Grupos} />
                            <PrivateRoute path="/acesso-as-leis" component={AcessoAsLeis} />
                            <Route path="/login" component={Login} />
                            <Route path="/recuperar-senha" component={RecuperarSenha} />
                            <Route path="/cadastro/:codigo?" component={Cadastro} />
                            <Route path="*" component={PageNotFound} />
                        </Switch>
                    </Container>
                    <DisplayMenu />
                </Router>
            </LoaddingPage>
        </ThemeProvider>
    )
}

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={props =>
            Usuario.isLogado() ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
    />
)

const theme = createTheme({
    typography: {
        fontSize: 18,
        fontFamily: 'Varela Round'
    },
    palette: {
        primary: {
            main: '#3b5998', // mais clara: #516eab
            contrastText: '#FFF'
        },
        secondary: {
            main: '#FFFFFF'
        },
        background: {
            default: '#f0f2f5'
        },
        success: {
            main: '#36b37e'
        },
        error: {
            main: '#ff000a'
        }
    },
    overrides: {
        MuiRadio: {
            root: {
                // color: 'green',
            },
            colorSecondary: {
                '&$checked': {
                    color: '#1976d2',
                },
            },
        },
        MuiCheckbox: {
            root: {
                // color: 'green',
            },
            colorSecondary: {
                '&$checked': {
                    color: '#1976d2',
                },
            },
        },
    },
})

const routesWithBottomMenu = ['/', '/estatisticas', '/grupos', '/ranking', '/perfil']

const DisplayMenu = withRouter(({ location }) => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0

    const index = routesWithBottomMenu.indexOf(location.pathname.padEnd())

    return index !== -1 && <Menu current={location.pathname} />
})

